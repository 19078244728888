import { Controller } from "@hotwired/stimulus"
import jquery from "jquery"

// Connects to data-controller="daterangepicker"
export default class extends Controller {
  static values = {
    format: { type: String, default: "dd.MM.yyyy" },
    separator: { type: String, default: "," },
  }

  connect() {
    window.jQuery = jquery
    window.$ = window.jQuery

    this.onApply = this.onApply.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onShow = this.onShow.bind(this);

    window.n = this;
    this.addObservers();

    $(this.element).daterangepicker(this.options);
  }


  addObservers() {
    $(this.element).on("apply.daterangepicker", this.onApply);
    $(this.element).on("cancel.daterangepicker", this.onCancel);
    $(this.element).on("show.daterangepicker", this.onShow);
  }

  onApply(event, picker) {
    this.element.value = picker.startDate.format(this.formatValue) + this.separatorValue + picker.endDate.format(this.formatValue);
  }

  onCancel(event, picker) {
    this.element.value = "";
  }

  onShow(event, picker) {
    this.addNames(picker.container.find(".left"), "start");
    this.addNames(picker.container.find(".right"), "end");
  }

  addNames(container, prefix) {
    container.find(".hourselect").attr("name", `${prefix}_hour`).attr("title", "Godzina");
    container.find(".minuteselect").attr("name", `${prefix}_minute`).attr("title", "Minuta");
    container.find(".secondselect").attr("name", `${prefix}_second`).attr("title", "Sekunda");
  }

  get options() {
    return {
      parentEl: "body",
      autoUpdateInput: false,
      timePicker: true,
      timePickerSeconds: true,
      timePicker24Hour: true,
      locale: {
        format: this.formatValue,
        separator: this.separatorValue,
        daysOfWeek: this.daysOfWeek,
        monthNames: this.monthNames,
        applyLabel: "Zatwierdź",
        cancelLabel: "Anuluj"
      }
    };
  }

  get daysOfWeek() {
    return ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"];
  }

  get monthNames() {
    return [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień"
    ];
  }
}
