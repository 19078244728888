import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-element"
export default class extends Controller {
  static targets = ["element"]
  static values = {
    formAction: String,
    formMethod: String,
    submitterName: { type: String, default: "remove_field" }
  }

  remove(event) {
    event.preventDefault();
    const form = this.form;
    const submitter = this.submitter;

    this.elementTarget.remove();

    form.appendChild(submitter);
    submitter.click();
    form.removeChild(submitter);
  }

  get form() {
    return this.element.closest("form");
  }

  get submitter() {
    let submitter = document.createElement("input");

    submitter.type = "submit";
    submitter.name = this.submitterNameValue;
    submitter.value = true;
    submitter.hidden = true;

    if (this.formActionValue) submitter.formAction = this.formActionValue;
    if (this.formMethodValue) submitter.formMethod = this.formMethodValue;

    return submitter;
  }
}
