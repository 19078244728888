import { Controller } from "@hotwired/stimulus"
import hljs from "highlight.js/lib/core";
import xml from "highlight.js/lib/languages/xml";
import json from "highlight.js/lib/languages/json";

// Connects to data-controller="highlight"
export default class extends Controller {
  static values = {
    language: String,
    code: String,
    preClass: { type: String, default: "" },
    codeClass: { type: String, default: "" }
  }

  connect() {
    this.registerLanguage();
    this.element.innerHTML = this.html;
  }

  get html() {
    return `
      <pre class="language-${this.languageValue} ${this.preClassValue}">
        <code class="hljs ${this.codeClassValue}">
          ${this.highlightedCode}
        </code>
      </pre>
    `;
  }

  get highlightedCode(){
    return hljs.highlight(
      this.codeValue, { language: this.languageValue }
    ).value;
  }

  registerLanguage() {
    if (!hljs.getLanguage(this.languageValue)) {
      hljs.registerLanguage(this.languageValue, this.getLanguage);
    }
  }

  get getLanguage() {
    return {
      "xml": xml,
      "json": json
    }[this.languageValue]
  }
}
