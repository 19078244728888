import { Controller } from "@hotwired/stimulus"
import HugeUploader from "huge-uploader";

// Connects to data-controller="huge-uploader"
export default class extends Controller {
  static values = {
    url: String,
    method: { type: String, default: "POST" }
  }

  upload(event) {
    event.preventDefault();
    const uploader = new HugeUploader({
      endpoint: this.urlValue,
      file: event.target.files[0],
      headers: this.headers,
      method: this.methodValue,
      inputName: event.target.name
    });

    uploader.on("progress", event => {
      Turbo.renderStreamMessage(event.detail.body)
    });
  }

  get headers() {
    return {
      "X-CSRF-Token": this.token,
      "Accept": "text/vnd.turbo-stream.html"
    }
  }

  get token() {
    return document.head.querySelector("meta[name=csrf-token]")?.content
  }
}
