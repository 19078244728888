import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    element: String,
    name: String
  }

  toggle(event) {
    event.preventDefault()
    this.elements.forEach(element => this.classNames.forEach(className => element.classList.toggle(className)))
  }

  get elements() {
    return document.querySelectorAll(this.elementValue)
  }

  get classNames() {
    return this.nameValue.split(" ")
  }
}
